.dateTxt {
  color: var(--black-text-color);
  margin-bottom: 30px;
}

.topWidgetWrapper {
  display: flex;
  margin: 30px 0px;
}

.topWidgetWrapper>div {
  margin-right: 30px;
}

.graphWrapper {
  margin-bottom: 40px;
}

.marginBtmMod {
  margin-bottom: 10px;
}

@media (max-width: 990px) {
  .topWidgetWrapper {
    flex-direction: column;
  }

  .topWidgetWrapper>div {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}