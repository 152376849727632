.headerPage {
  font-size: var(--font-24);
  font-weight: 700;
  margin-top: 60px;
  margin-bottom: 40px;
  color: var(--black-text-color);
}

.whiteContainer {
  padding: 40px;
  color: var(--black-text-color);
  font-size: var(--font-16);
}

.caseTitle {
  margin-bottom: 20px;
}

.caseLi,
.caseLiLast {
  margin-bottom: 14px;
  padding-left: 14px;
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--black-text-color);
    position: absolute;
    left: 0;
    top: 9px;
  }
}
.caseLiLast {
  margin-bottom: 0px;
}

.btnCase {
  margin-top: 16px;
}

.btnSale {
  margin-top: 30px;
  max-width: 125px;
}

.caseTxtBold {
  font-weight: 700;
}

.saleWrapper {
  margin-top: 40px;
}
