.whiteConteiner {
  padding: 0;
  /*  max-width: 480px; */
  max-width: fit-content;
  border-radius: 10px;
}

.audienceTab :global {
  .simplebar-wrapper {
    max-height: 400px;
    width: 110%;
  }
  .simplebar-track.simplebar-vertical {
    right: -13px;
  }
}
.tabTitle {
  display: flex;
  color: var(--black-text-color);
  font-size: var(--font-12);
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  border-bottom: 1px solid var(--grey-border-color);
  background-color: var(--violet-tab-bg);
  border-radius: 10px 10px 0px 0px;
}
.row {
  display: flex;
  color: var(--black-text-color);
  font-size: var(--font-14);
  align-items: center;
  border-bottom: 1px solid var(--grey-border-color);
}
.row:last-child {
  border: none;
}
.rowLeft {
  width: 350px;
  min-width: 260px;
  padding: 10px 20px;
  border-right: 1px solid var(--grey-border-color);
}
.rowRight {
  text-align: center;
  width: 130px;
  padding: 10px 20px;
}

.downloadBtnWrap {
  border-top: 1px solid var(--grey-border-color);
  transition: all 0.5s;
  border-bottom-left-radius: var(--common-border-radius);
  border-bottom-right-radius: var(--common-border-radius);
}

.downloadBtnWrap:hover {
  background-color: var(--btn-white-hover);
}