.mainWrapper {
  padding: 1px 0px 0px 260px;
  background-color: var(--white);
}
.main {
  position: relative;
  overflow: visible;
  width: 100%;
  padding: 0px 20px 60px 115px;
}

.inner {
  width: 100%;
  max-width: 1170px;
}

.noTopPadding {
  padding-top: 0;
}

.noBottomPadding {
  padding-bottom: 0;
}

.noLeftPadding {
  padding-left: 0;
}

.noRightPadding {
  padding-right: 0;
}

.fullHeight {
  position: relative;
  min-height: 100vh;
}

@media (max-width: 1440px) {
  .main {
    padding: 0px 40px 115px 40px;
  }
}
