.headerPage {
  font-size: var(--font-24);
  font-weight: 700;
  margin-top: 60px;
  margin-bottom: 40px;
  color: var(--black-text-color);
}

.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.inputWrapper > div {
  max-width: 300px;
  margin-right: 30px;
}

.buttonWrap {
  width: 120px;
}

.webinarBtn {
  width: 130px;
  margin-top: 30px;
}
