:root {
  /* blue */
  --primary-main-color: #19b5ff;
  --btn-white-hover: #ecf9ff;
  --dark-blue: #343c57;
  --hover-menu: #3c4461;

  /* violet */
  --violet-main-color: #727fff;
  --violet-bg-menu: #434c6b;
  --violet-tab-bg: #f5f6fa;

  /* grey */
  --grey-text-color: #848389;
  --grey-border-color: #e6e6e6;
  --grey-bg-color: #f2f2f2;
  --grey-light-color: #dedede;
  --grey-placeholder-color: #9f9f9f;
  --grey-dark-second: #636363;
  --grey-dark-disabled: #6363634d;

  /* red */
  --red: #ff0000;
  --red-main-color: #f44747;
  --error-red-color: #f6584e;
  --alert-border: #ff8d8d;
  --alert-bg: #ffbebd;

  /* white */
  --white: #fff;

  /* black */
  --black: #000000;
  --black-text-color: #4d4f5c;
}
