.funnelWrap {
  background-color: var(--white);
  padding: 20px;
  border: 1px solid var(--grey-border-color);
  border-radius: var(--common-border-radius);
  color: var(--black-text-color);
  font-size: 14px;
  line-height: 17px;
  min-height: 370px;
  position: relative;
}

.dateTxt {
  color: var(--black-text-color);
  margin-bottom: 30px;
}

.funnelLine {
  display: flex;
  max-width: 700px;
  gap: 30px;
  margin-bottom: 39px;
}

.funnelLineHeader {
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 21px;

  .barTitle {
    color: var(--grey-text-color);
    font-size: 16px;
    line-height: 20px;
    min-width: fit-content;
  }

  .values,
  .percent {
    font-weight: 400;
  }
}

.barTitle {
  min-width: 180px;
}

.barHeader {
  opacity: 0;
}

.values {
  min-width: 80px;
  font-weight: 600;
}

.percent {
  min-width: 60px;
  font-weight: 600;
}

.fullWidth {
  width: 100%
}