.headerPage {
  font-size: var(--font-24);
  font-weight: 700;
  margin-top: 60px;
  margin-bottom: 40px;
  color: var(--black-text-color);
}
.blockName {
  font-size: var(--font-20);
  color: var(--black-text-color);
  margin-bottom: 30px;
  margin-top: 60px;
}

.postWrapper > div {
  margin-bottom: 20px;
}

.goBack {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

.goBackTxt {
  margin-left: 10px;
}

.loaderWrapper {
  position: relative;
  margin-top: 55px;
}

.skeletonWrap {
  width: 120px;
  display: inline-block;
}

.headerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.searchField {
  box-shadow: inset #abacaf 0 0 0 2px;
  border: 0;
  background: rgba(0, 0, 0, 0);
  appearance: none;
  width: 40%;
  position: relative;
  border-radius: 3px;
  padding: 9px 12px;
  line-height: 1.4;
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  transition: all 0.2s ease;
  &:hover {
    box-shadow: 0 0 0 0 #fff inset, #1de9b6 0 0 0 2px;
  }
  &:focus {
    background: #fff;
    outline: 0;
    box-shadow: 0 0 0 0 #fff inset, #1de9b6 0 0 0 3px;
  }
}
