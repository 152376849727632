.headerPage {
  font-size: var(--font-24);
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 40px;
  color: var(--black-text-color);
}

.greyLightTxt {
  color: var(--grey-text-color);
  font-size: var(--font-12);
}

.blackTxt {
  color: var(--black-text-color);
  font-size: var(--font-16);
}

.blackBoldTxt {
  color: var(--black-text-color);
  font-size: var(--font-16);
  font-weight: 700;
}

//Стиль для всех постов, чтобы между ними был отступ
.postWrapper > div {
  margin-bottom: 20px;
}

// Заголовок для блоков с виджетами на конкретных страницах вебинара/видео/cтатей
.blockName {
  font-size: var(--font-20);
  color: var(--black-text-color);
  margin-bottom: 14px;
  margin-top: 60px;
}
// Серый подзаголовок для блоков с виджетами на конкретных страницах вебинара/видео/cтатей
.blockSubName {
  font-size: var(--font-12);
  color: var(--grey-text-color);
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 400;
  text-transform: uppercase;
}

// Враппер для виджетов с числами на текущих страницах  вебинара/видео/cтатей ( места где 3 колонки )
.valueWidgetWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.valueWidgetWrapper > div {
  margin-bottom: 30px;
}

@media (max-width: 1300px) {
  .valueWidgetWrapper {
    justify-content: flex-start;
  }
}

@media (max-width: 1080px) {
  .valueWidgetWrapper {
    justify-content: space-between;
  }
}
