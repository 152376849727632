.valueWidget {
  width: 370px;
  background-color: #fff;
  border: 1px solid var(--grey-border-color);
  border-radius: 10px;
  padding: 20px;
}
.widgetTitle {
  position: relative;
  color: var(--grey-text-color);
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.widgetText {
  color: var(--black-text-color);
  font-size: 24px;
  font-weight: 700;
  height: 31px;
}

.question{
  position: relative;
}

.hint {
  display: none;
  position: absolute;
  top: -95px;
  right: -170px;
  padding: 10px;
  border: 1px solid var(--grey-border-color);
  background-color: #fff;
}

.question:hover + .hint {
  display: block
}

.benchmark {
  background: var(--violet-bg-menu);
  color: var(--white);
  padding: 4px 8px;
  text-wrap: nowrap;
  font-size: 12px;
  line-height: 14px;
  border-radius: 20px;
}

.infoWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 1600px) {
  .valueWidget {
    width: 315px;
  }
}

@media (max-width: 1300px) {
  .valueWidget {
    margin-right: 30px;
  }
}
@media (max-width: 1080px) {
  .valueWidget {
    margin-right: 0px;
  }
}

@media (max-width: 990px) {
  .valueWidget {
    width: 100%;
  }
}
