.container {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}

.back {
  width: 100%;
  background-color: var(--grey-bg-color);

}

.bar {
  position: absolute;
  width: 50%;
  transition: width 0.9s ease-in-out;
}

.bar_form {
  height: 10px;
  border-radius: 5rem;
}