.wrapper {
  border-radius: 20px;
  padding: 3px 8px 5px;
}

/* Заменить цвет на правильный */
.wrapperPositive {
  background-color: #74fcbd;
  color: #0f6b0f;
}

.wrapperNegative {
  background-color: #FDDDDF;
  color: #FF3B45
}

.wrapperZero {
  background-color: #e0e0e0;
  color: #505050
}

.percent {
  display: flex;
  font-size: 12px;
  line-height: 14px;
  padding-top: 2px;
}


/* Заменить картинку на правильную */
.percentPositive {
  &::before {
    content: url('../../../public/svg/arrow_up_green.svg');
    display: block;
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-right: 2px;
  }
}

.percentNegative {
  &::before {
    content: url('../../../public/svg/arrow_down_red.svg');
    display: block;
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-right: 2px;
  }
}