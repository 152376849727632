.title {
  font-size: var(--font-20);
  margin-bottom: 20px;
}

.subtitle {
  margin-bottom: 25px;
  font-size: var(--font-16);
}
.inputWrap {
  margin-bottom: 20px;
}
.buttonWrap {
  max-width: 120px;
  margin-top: 30px;
}
.btn {
  margin-top: 40px;
}
