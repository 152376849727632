.blurOverlay {
  position: absolute;
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.6);
  border-radius: var(--common-border-radius);
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.blutTitle {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 0 30px;
}