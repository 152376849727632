.title {
  font-size: var(--font-16);
  color: var(--grey-text-color);
}

.graphHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

}

.loaderWrapper {
  height: 30vw;
  position: relative;
}
