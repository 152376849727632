.dateTxt {
  color: var(--black-text-color);
  margin-bottom: 30px;
}

.contentWrap {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 0px;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}

.div2 {
  grid-area: 2 / 1 / 3 / 2;
}

.contentRight {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 30px;
  width: fit-content;
  grid-area: 2 / 2 / 3 / 3;
}

.reportWrap {
  color: #4d4f5c;
}

.warning {
  margin-bottom: 20px;
  min-width: 100%;
}

.reportTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 14px;
}

.reportDesc {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 20px;
}

@media (max-width: 990px) {
  .btnWrapper {
    flex-direction: column;
  }
}
