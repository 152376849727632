.infoWidget {
  position: relative;
}
.widgetTopTxt {
  color: var(--grey-text-color);
  font-size: var(--font-16);
  font-weight: 400;
  margin-bottom: 15px;
}

.widgetMiddleTxt {
  color: var(--black-text-color);
  font-size: var(--font-18);
  font-weight: 700;
}

.widgetBottomTxt {
  color: var(--grey-text-color);
  font-size: var(--font-16);
  margin-top: 10px;
  font-weight: 400;
}

.widgetIcon {
  position: absolute;
  right: 20px;
  cursor: pointer;
}
