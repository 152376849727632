.goBack {
  margin-bottom: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.goBackTxt {
  margin-left: 10px;
  color: var(--black-text-color);
}
