.title {
  font-size: var(--font-24);
  color: var(--black-text-color);
}
.date {
  font-size: var(--font-16);
  color: var(--black-text-color);
  margin-top: 14px;
  margin-bottom: 40px;
}

.audienceTitle {
  text-transform: uppercase;
  font-size: var(--font-12);
  color: var(--grey-text-color);
  padding-bottom: 10px;
  letter-spacing: 1.2px;
}

.audience {
  font-size: var(--font-16px);
  color: var(--black-text-color);
}

.btnBlocksWrapper {
  position: absolute;
  bottom: 0;
}

.btnNav {
  position: relative;
  text-transform: uppercase;
  background: transparent;
  outline: none;
  border: none;
  color: #4D4F5C;
  padding: 16px 0 14px;
  margin-right: 54px;
  overflow: hidden;
  cursor: pointer;
}

.btnNavActive::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 7px;
  background: #19B5FF;
  bottom: 0;
  left: 0;
  transform: translateY(50%);
  border-radius: 9px;
}

.btnNav:last-child {
  margin-right: 0px;
}