.cyan {
  background-color: var(--primary-main-color)
}

.bilberry {
  background-color: #465DAD;
}

.green {
  background-color: #8AB861;
}

.orange {
  background-color: #FF804F;
}

.pink {
  background-color: #FF6F9A;
}

.yellow {
  background-color: #FCC262;
}