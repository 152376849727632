.categoryWrapper {
  margin-bottom: 30px;
}
.category {
  margin-bottom: 10px;
  text-transform: uppercase;
}

.valueWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.valueWidget {
  margin-right: 30px;
}
.bottomTxt {
  margin-bottom: 30px;
}

.btn {
  max-width: 125px;
}
