.loggedOutWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  row-gap: 10px;
  padding-top: 70px;
}

.loggedInWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  row-gap: 10px;
  padding-top: 70px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 350px;
  row-gap: 10px;
}
