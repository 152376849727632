.popupOverlay {
  position: fixed;
  background: rgba(52, 60, 87, 0.3);
  z-index: 101;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.2s;
  pointer-events: none;
}

.active {
  opacity: 1 !important;
  pointer-events: all;
}

.title {
  font-size: var(--font-20);
}

.whiteConteiner {
  position: relative;
  padding: 30px;
  width: 450px;
  border-radius: 10px;
}
.popupContent {
  opacity: 0;
  z-index: 102;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.popupClose {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 24px;
    display: block;
    width: 24px;
    height: 2px;
    background: var(--primary-main-color);
  }
  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
