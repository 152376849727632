:root {
  --container-size: 1110px;
  --common-border-radius: 10px;

  // font-size
  --font-12: 12px;
  --font-14: 14px;
  --font-16: 16px;
  --font-18: 18px;
  --font-20: 20px;
  --font-24: 24px;
}
