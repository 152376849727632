.whiteConteiner {
  max-width: 480px;
  padding: 15px;
}
.warning {
  display: flex;
  align-items: center;
}

.warningIcon {
  min-height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-color: var(--dark-blue);
  position: relative;
  margin-right: 15px;
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  path {
    fill: #ededed;
  }
}

.warningTxt {
  color: var(--dark-blue);
  font-size: var(--font-16);
}
