.wrapperFade {
  position: relative;
  height: 100%;
  &::after {
    position: absolute;
    top: 0;
    right: -1%;
    width: 20%;
    height: 100%;
    background: transparent linear-gradient(90deg, #f8f8f800 0%, var(--grey-bg-color) 100%) 0% 0%
      no-repeat padding-box;
    content: '';
    pointer-events: none;
  }

  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: -1%;
    width: 20%;
    height: 100%;
    background: transparent linear-gradient(90deg, var(--grey-bg-color) 0%, #f8f8f800 100%) 0% 0%
      no-repeat padding-box;
    content: '';
    pointer-events: none;
  }
}

.wrapperNoRightFade {
  &::after {
    content: none;
  }
}

.wrapperNoLeftFade {
  &::before {
    content: none;
  }
}

.toggle {
  position: relative;
  display: flex;
  overflow: auto;
  overflow: -moz-scrollbars-none;
  height: 100%;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.option {
  display: flex;
  height: 100%;
  flex-shrink: 0;
  align-items: center;
  padding: 10px 0px;
  border: none;
  margin-right: 40px;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  color: var(--dark-blue);
  font-weight: 600;
  &:last-of-type {
    margin-right: 20px;
  }
}

.optionActive {
  color: var(--primary-main-color);
  font-weight: 600;
}

.underline {
  position: absolute;
  bottom: 0;
  width: 50px;
  height: 3px;
  background-color: var(--primary-main-color);
  border-radius: 20px;
  transition: width 1s, transform 0.5s;
}

@media (min-width: 768px) {
  .wrapperFade {
    &::after {
      content: none;
    }
  }
}

@media (min-width: 1024px) {
  .option {
    margin-right: 40px;
    font-size: 16px;
  }
}
