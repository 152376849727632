.inputWrapper {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  height: 50px;
  padding: 5px 0;
  padding-right: 20px;
  padding-left: 14px;
  border: 1px solid var(--grey-border-color);
  border-radius: var(--common-border-radius);
  font-size: 16px;
  outline: none;

  &::-webkit-input-placeholder {
    color: var(--grey-placeholder-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  &:focus {
    border: 1px solid var(--primary-main-color);
  }
}

.input.error {
  border: 1px solid var(--red);
}

.innerContainer {
  width: 100%;
  max-width: 100%;
  margin-right: 0;
}

.labelInput {
  position: relative;
  font-size: 12px;
  line-height: 27px;
  color: var(--grey-text-color);
  font-weight: 400;
  text-transform: uppercase;
}

.outerName {
  margin-bottom: 8px;
}

.errorCheck {
  margin-top: 10px;
  color: var(--red);
  font-size: 14px;
  line-height: 19px;
}

.capitalized {
  text-transform: capitalize;

  &::placeholder {
    text-transform: none;
  }
}

.tolowercase {
  text-transform: lowercase;

  &::placeholder {
    text-transform: none;
  }
}

@media (min-width: 768px) {
  .innerContainer {
    width: 100%;
  }
}
