.navWrap {
  position: relative;
}
.navbar {
  position: fixed;
  background-color: var(--violet-bg-menu);
  width: 260px;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}
.liWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 130px);
  padding-top: 20px;
}
.listElement {
  padding: 20px;
  color: var(--white);
  display: flex;
  align-items: center;
}
.listElementTxt {
  margin-left: 10px;
  display: inline-block;
}
.listItem:hover:not(.listItemActive) {
  background-color: var(--hover-menu);
}

.listItemActive {
  background-color: var(--dark-blue);
  display: block;
  border-left: 5px solid var(--primary-main-color);
  li {
    padding-left: 15px;
  }
}

.listItemActive * {
  fill: var(--primary-main-color);
}

.listItemDisable {
  opacity: 0.5;
  pointer-events: none;
}

.navbarLogo {
  height: 70px;
  background-color: var(--dark-blue);
  display: flex;
  padding-left: 25px;
  align-items: center;
}
.listItem {
  display: block;
}
.verticalLine {
  width: 1px;
  height: 24px;
  background-color: white;
  margin: 0 16px;
}
.userName {
  font-family: 'Rubik';
  color: white;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
}
.navbarBottom {
  cursor: pointer;
}
.navbarBottom:hover {
  background-color: var(--hover-menu);
}