.button {
  height: 46px;
  flex-shrink: 0;
  padding: 0 22px;
  border: 1px solid var(--primary-main-color);
  background-color: var(--primary-main-color);
  border-radius: 40px;
  color: var(--white);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;
  line-height: 19px;
  outline: none;
  transition: all 0.5s;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  & a {
    transition: all 0.5s;
    color: var(--primary-main-color);
  }
}

.buttonDownload {
  display: flex;
  align-items: center;
  justify-content: center;
  &::after {
    content: url('../../../public/svg/download_white.svg');
    display: block;
    width: 14px;
    height: 14px;
    margin-left: 8px;
  }
}
.buttonWhite {
  padding: 0 20px;
  border-color: var(--primary-main-color);
  background-color: var(--white);
  font-weight: 600;
}

.noBg {
  background-color: transparent;
  border: 1px solid var(--primary-main-color);
  color: var(--primary-main-color);
}

@media (hover) {
  .button:hover {
    border-color: #77d4ff;
    background-color: #77d4ff;
  }
  .noBg:hover {
    background-color: var(--btn-white-hover);
  }
  .buttonWhite:hover {
    background-color: var(--btn-white-hover);
  }
  .downloadNoBg:hover {
    background-color: transparent;
  }
}

.downloadNoBg {
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-main-color);
  background-color: transparent;
  &::after {
    content: url('../../../public/svg/download.svg');
    display: block;
    width: 14px;
    height: 14px;
    margin-left: 8px;
  }
}