.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
}

.wrapper {
  display: flex;
  align-items: center;
}

.text {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  border-left: 1px solid #000000;
}

.title {
  padding-right: 10px;
}

.link {
  color: #56b5ff;
  text-decoration: underline;
}
